import React, { useEffect, useState } from 'react'
import TextField from '@mui/material/TextField'

const TextRangeInput = ({
  onValueChange,
  value = '',
  min = 0,
  max = 100,
  precision = 0,
  ...props
}) => {
  const [internalValue, setInternalValue] = useState(value)
  const [displayValue, setDisplayValue] = useState(value)
  const [isFocused, setIsFocused] = useState(false)

  useEffect(() => {
    value !== internalValue && onValueChange && onValueChange(internalValue)
  }, [internalValue])

  useEffect(() => {
    setDisplayValue(value)
    setInternalValue(value)
  }, [value])

  const validateAndCorrectInput = () => {
    let num = parseFloat(displayValue)
    if (isNaN(num)) {
      num = min // Default to 0 if the input is not a valid number
    } else {
      num = Math.max(min, Math.min(max, num)) // Ensure the number is between min and max
    }

    // Truncate to one decimal place
    const correctedValue = num.toFixed(precision)

    setInternalValue(correctedValue)
    setDisplayValue(correctedValue)
  }

  const handleFocus = () => {
    setIsFocused(true)
    setDisplayValue(value)
  }

  const handleBlur = () => {
    setIsFocused(false)
    validateAndCorrectInput()
  }

  const handleChange = event => {
    setDisplayValue(event.target.value)
  }

  return (
    <TextField
      value={isFocused ? displayValue : value}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onChange={handleChange}
      {...props}
    />
  )
}

export default TextRangeInput

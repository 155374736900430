import React, { useState, useEffect, useContext } from 'react'
import {
  TextField,
  MenuItem,
  Typography,
  Box,
  CardContent,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  InputAdornment,
  Button,
  useTheme,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { PieChart } from '@mui/x-charts/PieChart'
import { gql, useMutation } from '@apollo/client'
import RsCard from '../../../Common/UIComponents/Card/RsCard'
import {
  calculateMonthlyPrincipalAndInterest,
  calculateMonthlyInsurance,
  calculateMonthlyPropertyTax,
  calculatePMI,
} from '../../../Utils/MortgageMathUtils'
import { numberToCurrency } from '../../../Utils/NumberUtils'
import DisclaimerTextModal from '@/components/Common/UIComponents/Modal/DisclaimerTextModal'
import useMutationSnackbarMessages from '@/components/CustomHooks/MutationSnackbarHooks'
import { CurrentUserContext } from '@/components/Homebuyer/Contexts/CurrentUserContext'
import DownPaymentInput from '@/components/Homebuyer/Common/MortgageCalculator/DownPaymentInput'

export const MORTGAGE_CALCULATOR_FRAGMENT = gql`
  fragment mortgageCalculatorPropertyFragment on Property {
    id
    list_price
    simple_type
  }
  fragment mortgageCalculatorUserFragment on User {
    id
    mortgage_rate_basis_points
    mortgage_type
    down_payment_type
    down_payment_amount
    current_mortgage_rates {
      mortgage_type
      mortgage_label
      interest_rate
    }
  }
`

const UPDATE_USER_MORTGAGE_DETAILS = gql`
  mutation updateUserMortgageDetails($input: UpdateUserInput!) {
    update_user(input: $input) {
      user {
        id
        mortgage_rate_basis_points
        mortgage_type
        down_payment_type
        down_payment_amount
      }
    }
  }
`

const Index = ({
  userId,
  propertyType,
  initialListingPrice = 0,
  mortgageOptions,
  initialInterestRate = undefined,
  initialMortgageType = undefined,
  initialDownPaymentAmount = 20,
  initialDownPaymentType = 'percent',
}) => {
  const theme = useTheme()
  const { loggedIn, showSignUp } = useContext(CurrentUserContext)
  const insurancePercentage = 0.007 // National average insurance rates come out to ~.7% of home value
  const propertyTaxPercentage = 0.01 // Rough estimate for property tax
  const [listingPrice, setListingPrice] = useState(initialListingPrice)
  const [userInterestRate, setUserInterestRate] = useState(initialInterestRate)
  const [mortgageType, setMortgageType] = useState(initialMortgageType)
  const [downPaymentType, setDownPaymentType] = useState(initialDownPaymentType)
  const [downPaymentAmount, setDownPaymentAmount] = useState(initialDownPaymentAmount)
  const [monthlyPrincipalAndInterest, setMonthlyPrincipalAndInterest] = useState(0)
  const [monthlyInsurance, setMonthlyInsurance] = useState(0)
  const [monthlyPropertyTax, setMonthlyPropertyTax] = useState(0)
  const [monthlyPMI, setMonthlyPMI] = useState(0)
  const [userSetInsurance, setUserSetInsurance] = useState(false)
  const mutationSnackbarMessages = useMutationSnackbarMessages({
    dataAttribute: 'update_user',
    customSuccessMessage: 'Successfully saved mortgage options',
  })
  const [updateUser, { loading }] = useMutation(UPDATE_USER_MORTGAGE_DETAILS, {
    ...mutationSnackbarMessages,
  })

  if (propertyType !== 'SFR') {
    return null
  }

  const selectedMortgage =
    mortgageOptions.find(option => option.mortgage_type === mortgageType) ||
    mortgageOptions.find(option => option.mortgage_type === 'CONVENTIONAL_30')

  // Fallback to 6% if there is no user saved interest rate or a current interest rate from our external sources
  const effectiveInterestRate =
    userInterestRate !== undefined
      ? userInterestRate
      : parseFloat(selectedMortgage.interest_rate) || 6.0

  let downPaymentPercentage

  if (downPaymentType === 'percent') {
    downPaymentPercentage = downPaymentAmount
  } else {
    downPaymentPercentage = ((downPaymentAmount * 100.0) / listingPrice).toFixed(1)
  }

  const handleClick = () => {
    if (loggedIn) {
      updateUser({
        variables: {
          input: {
            id: userId,
            mortgage_rate_basis_points: parseInt(effectiveInterestRate * 100, 10),
            mortgage_type: mortgageType,
            down_payment_amount: parseInt(downPaymentAmount, 10),
            down_payment_type: downPaymentType,
          },
        },
      })
    } else {
      showSignUp()
    }
  }

  useEffect(() => {
    calculateMonthlyPayments()
  }, [listingPrice, effectiveInterestRate, mortgageType, downPaymentPercentage])

  const calculateMonthlyPayments = () => {
    if (!listingPrice || !effectiveInterestRate) return

    const principal = parseFloat(listingPrice)
    const downPayment = (principal * downPaymentPercentage) / 100
    const loanAmount = principal - downPayment
    const annualInterestRate = parseFloat(effectiveInterestRate) / 100

    const monthlyPrincipalAndInterestPayment = calculateMonthlyPrincipalAndInterest(
      loanAmount,
      annualInterestRate,
      mortgageType
    )

    setMonthlyPrincipalAndInterest(monthlyPrincipalAndInterestPayment)

    if (!userSetInsurance) {
      const monthlyInsurancePayment = calculateMonthlyInsurance(listingPrice, insurancePercentage)
      setMonthlyInsurance(monthlyInsurancePayment)
    }

    const monthlyPropertyTaxPayment = calculateMonthlyPropertyTax(
      listingPrice,
      propertyTaxPercentage
    )
    setMonthlyPropertyTax(monthlyPropertyTaxPayment)

    const monthlyPMIPayment = calculatePMI(loanAmount, downPaymentPercentage)
    setMonthlyPMI(monthlyPMIPayment)
  }

  const handleInsuranceChange = e => {
    setMonthlyInsurance(e.target.value)
    setUserSetInsurance(true)
  }

  const totalMonthlyPayment =
    parseFloat(monthlyPrincipalAndInterest) +
    parseFloat(monthlyInsurance) +
    parseFloat(monthlyPropertyTax) +
    parseFloat(monthlyPMI)

  const data = [
    {
      name: 'Principal & Interest',
      value: parseFloat(monthlyPrincipalAndInterest),
      color: theme.palette.primary.main,
    },
    { name: 'Insurance', value: parseFloat(monthlyInsurance), color: theme.palette.warning.light },
    {
      name: 'Property Tax',
      value: parseFloat(monthlyPropertyTax),
      color: theme.palette.success.light,
    },
    { name: 'PMI', value: parseFloat(monthlyPMI), color: theme.palette.info.light },
  ]

  return (
    <RsCard cardProps={{ sx: { boxShadow: 'none' } }} title="Cost Calculator">
      <CardContent>
        <Box
          sx={{
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <PieChart
            series={[
              {
                data,
                innerRadius: 100,
                outerRadius: 110,
                paddingAngle: 3,
                cornerRadius: 5,
              },
            ]}
            sx={{ height: 300, width: '100%' }}
            type="pie"
            margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
            height={250}
            tooltip="none"
          />
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              textAlign: 'center',
            }}
          >
            <Typography variant="body2">Estimated Payment</Typography>
            <Typography variant="h3">
              {numberToCurrency(totalMonthlyPayment.toFixed(0))}
              <Typography variant="subtitle1" component="span">
                /mo
              </Typography>
            </Typography>
          </Box>
        </Box>
        <Box sx={{ padding: 3 }}>
          {data.map((entry, index) => (
            <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <Box sx={{ width: 16, height: 16, bgcolor: entry.color, borderRadius: 1, mr: 2 }} />
              <Typography variant="body1" sx={{ flexGrow: 1 }}>
                {entry.name}
              </Typography>
              <Typography variant="body1">{numberToCurrency(entry.value.toFixed(0))}</Typography>
            </Box>
          ))}
        </Box>
        <Divider />
        <Accordion sx={{ mt: 0 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Property Details</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TextField
              label="Listing Price"
              value={listingPrice}
              onChange={e => setListingPrice(e.target.value)}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Monthly Insurance"
              value={monthlyInsurance}
              onChange={handleInsuranceChange}
              fullWidth
              margin="normal"
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
            />
          </AccordionDetails>
        </Accordion>
        <Divider />
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            Your Mortgage Settings
          </AccordionSummary>
          <AccordionDetails>
            <TextField
              label="Interest Rate (%)"
              type="number"
              value={effectiveInterestRate}
              onChange={e => setUserInterestRate(e.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              select
              id="mortgage-type"
              label="Mortgage Type"
              value={mortgageType}
              onChange={e => setMortgageType(e.target.value)}
              fullWidth
              margin="normal"
            >
              {mortgageOptions.map(option => (
                <MenuItem key={option.mortgage_type} value={option.mortgage_type}>
                  {option.mortgage_label}
                </MenuItem>
              ))}
            </TextField>
            <DownPaymentInput
              downPaymentType={downPaymentType}
              initialAmount={downPaymentAmount}
              price={listingPrice}
              onTypeChange={type => setDownPaymentType(type)}
              onAmountChange={amount => setDownPaymentAmount(amount)}
            />
            <Button
              sx={{ mt: 2 }}
              variant="contained"
              onClick={handleClick}
              disabled={loading}
              fullWidth
            >
              Save Settings
            </Button>
          </AccordionDetails>
        </Accordion>
        <Divider />
        <Box sx={{ mx: 2, mt: 2 }}>
          <DisclaimerTextModal smallText="Disclaimer" disclaimerTitle="Cost Calculator Disclaimer">
            The results provided by this mortgage calculator are intended for illustrative purposes
            only and are not guaranteed to be accurate. The estimates generated by this tool are
            based on the information provided by the user and do not constitute financial advice.
            Actual mortgage payments and interest rates may vary and are subject to approval by
            lenders. Additionally, other factors such as property taxes, homeowner's insurance,
            Private Mortgage Insurance (PMI), and other fees or expenses may impact your final
            monthly payment. Please consult with your agent for precise information and personalized
            advice regarding your mortgage options.
            <br />
            <br />
            Current interest rates are based on data provided by Freddie Mac.
            <br />
            <br />
            This product uses the FRED® API but is not endorsed or certified by the Federal Reserve
            Bank of St. Louis.
          </DisclaimerTextModal>
        </Box>
      </CardContent>
    </RsCard>
  )
}

export default Index

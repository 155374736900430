import { Box, Stack, Grid, useTheme, useMediaQuery, Container } from '@mui/material'
import RsSkeletonLoading from '@/components/Common/UIComponents/PageLayout/RsSkeletonLoading'

const LoadingListing = () => {
  const theme = useTheme()
  const isSmallerThanSm = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <>
      <Box sx={theme => ({ backgroundColor: theme.colors.alpha.white[100] })}>
        <Box
          sx={theme => ({
            backgroundColor: theme.colors.alpha.black[5],
            height: '60px',
          })}
        />
        <Container>
          <Stack direction="column" spacing={3} sx={{ py: 3 }}>
            <RsSkeletonLoading height={isSmallerThanSm ? '200px' : '450px'} />
            <RsSkeletonLoading height={isSmallerThanSm ? '100px' : '150px'} />
          </Stack>
        </Container>
      </Box>
      <Box
        sx={theme => ({
          backgroundColor: theme.colors.alpha.black[5],
        })}
      >
        <Container>
          <Box py={3}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={7}>
                <RsSkeletonLoading height="250px" />
              </Grid>
              <Grid item xs={12} sm={5}>
                <RsSkeletonLoading height="250px" />
              </Grid>
              <Grid item xs={12} sm={7}>
                <RsSkeletonLoading height="250px" />
              </Grid>
              <Grid item xs={12} sm={5}>
                <RsSkeletonLoading height="250px" />
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </>
  )
}

export default LoadingListing

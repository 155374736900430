import { gql, useMutation } from '@apollo/client'
import { Button, CircularProgress } from '@mui/material'
import useMutationSnackbarMessages from '~/components/CustomHooks/MutationSnackbarHooks'

const CREATE_SAVED_SEARCH_FROM_TEMPLATE = gql`
  mutation createSavedSearchFromShareableSearchLink(
    $input: CreateSavedSearchFromShareableSearchLinkMutationInput!
  ) {
    create_saved_search_from_shareable_search_link(input: $input) {
      saved_search {
        id
        search_name
        schedule
      }
      errors {
        key
        message
      }
    }
  }
`

const CreateSavedSearchButton = ({
  sharedSearchLinkId,
  name,
  schedule,
  onCompleted,
  shouldDisable,
}) => {
  const mutationSnackbarMessages = useMutationSnackbarMessages({
    dataAttribute: 'create_saved_search_from_shareable_search_link',
    customSuccessMessage: 'Search successfully saved!',
    afterCompletedFn: onCompleted,
  })

  const [createSavedSearch, { loading }] = useMutation(CREATE_SAVED_SEARCH_FROM_TEMPLATE, {
    ...mutationSnackbarMessages,
  })

  const handleClick = () => {
    if (loading) return
    createSavedSearch({
      variables: {
        input: {
          shareable_search_link_id: sharedSearchLinkId,
          search_name: name,
          schedule,
        },
      },
    })
  }

  return (
    <Button
      aria-label="confirm-save-search"
      variant="contained"
      fullWidth
      onClick={handleClick}
      disabled={loading || shouldDisable}
      endIcon={
        loading && (
          <CircularProgress sx={theme => ({ color: theme.colors.alpha.black[30] })} size={15} />
        )
      }
    >
      Save Search
    </Button>
  )
}

export default CreateSavedSearchButton

export const calculateMonthlyPrincipalAndInterest = (
  principal,
  annualInterestRate,
  mortgageType
) => {
  const numberOfYears = mortgageType === 'CONVENTIONAL_15' ? 15 : 30
  const numberOfPayments = numberOfYears * 12
  const monthlyInterestRate = annualInterestRate / 12
  if (monthlyInterestRate === 0) {
    return (principal / numberOfPayments).toFixed(0)
  }
  return (
    (principal * monthlyInterestRate) /
    (1 - (1 + monthlyInterestRate) ** -numberOfPayments)
  ).toFixed(0)
}

export const calculateMonthlyInsurance = (propertyValue, insurancePercentage) =>
  ((propertyValue * insurancePercentage) / 12).toFixed(0)

export const calculateMonthlyPropertyTax = (propertyValue, propertyTaxPercentage) =>
  ((propertyValue * propertyTaxPercentage) / 12).toFixed(0)

export const calculatePMI = (loanAmount, downPaymentPercentage) => {
  if (downPaymentPercentage < 20) {
    const annualPMIRate = 0.01 // Example PMI rate of 1%
    return ((loanAmount * annualPMIRate) / 12).toFixed(0)
  }
  return 0
}

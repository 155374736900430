import {
  Box,
  FormLabel,
  InputAdornment,
  styled,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material'
import React, { useState } from 'react'
import { grey } from '@mui/material/colors'
import TextRangeInput from '@/components/Homebuyer/Common/MortgageCalculator/TextRangeInput'

const DisabledTextField = styled(TextField)(({ theme }) => ({
  flexGrow: 1,
  mr: 1,
  '& legend': { display: 'none' },
  '& fieldset': { top: 0 },
  '& .Mui-disabled': {
    '& fieldset.MuiOutlinedInput-notchedOutline': { borderColor: grey[300] },
    '& .MuiTypography-root': { color: theme.palette.text.disabled },
  },
}))

const DownPaymentInput = ({
  downPaymentType,
  price,
  initialAmount,
  onTypeChange,
  onAmountChange,
}) => {
  const calcInitialPercentValue = () =>
    downPaymentType === 'percent' ? initialAmount : ((initialAmount * 100.0) / price).toFixed(0)
  const calcInitialDollarValue = () =>
    downPaymentType === 'percent' ? ((price * initialAmount) / 100).toFixed(0) : initialAmount

  const [type, setType] = useState(downPaymentType)
  const [percent, setPercent] = useState(calcInitialPercentValue)
  const [dollar, setDollar] = useState(calcInitialDollarValue)

  const handlePercentChange = value => {
    setPercent(value)
    setDollar(((price * value) / 100).toFixed(0))
    onAmountChange(value)
  }

  const handleDollarChange = value => {
    setPercent(((value * 100.0) / price).toFixed(0))
    setDollar(value)
    onAmountChange(value)
  }

  const handleTypeChange = (event, value) => {
    if (value === null) return
    setType(value)
    const amount = value === 'percent' ? percent : dollar
    onAmountChange(amount)
    onTypeChange(value)
  }

  const renderInputs = () => {
    if (type === 'percent') {
      return (
        <>
          <DisabledTextField
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            value={dollar}
            disabled
            id="dollar-downpayment"
            data-testid="dollar-downpayment"
          />
          <TextRangeInput
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            value={percent}
            onValueChange={handlePercentChange}
            precision={0}
            id="percentage-downpayment"
            data-testid="percentage-downpayment"
            sx={{ flexGrow: 1, ml: 1, '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
          />
        </>
      )
    }
    return (
      <>
        <TextRangeInput
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          onValueChange={handleDollarChange}
          value={dollar}
          min={0}
          id="dollar-downpayment"
          data-testid="dollar-downpayment"
          max={price}
          sx={{ flexGrow: 1, mr: 1, '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
        />
        <DisabledTextField
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
          value={percent}
          disabled
          id="percentage-downpayment"
          data-testid="percentage-downpayment"
        />
      </>
    )
  }

  return (
    <>
      <FormLabel component="div" sx={{ color: 'black', mb: 1, mt: 2 }}>
        Down Payment
      </FormLabel>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <ToggleButtonGroup
          value={type}
          color="primary"
          exclusive
          onChange={handleTypeChange}
          aria-label="down payment type"
          fullWidth
        >
          <ToggleButton value="amount" aria-label="dollar">
            Dollar Amount
          </ToggleButton>
          <ToggleButton value="percent" aria-label="percent">
            Percentage
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
      <Box sx={{ display: 'flex', mt: 1, mb: 3 }}>{renderInputs()}</Box>
    </>
  )
}

export default DownPaymentInput

import { useState } from 'react'
import { gql, useQuery } from '@apollo/client'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { Box, Container, Typography, Button } from '@mui/material'
import { SplitLayoutProvider } from '../Contexts/SplitLayoutContext'
import SplitLayout, { listingSearchFragment } from '../Common/SplitLayout'
import { reportError } from '../../Common/ErrorCapture'

const sortOptions = [
  { label: 'Recent Status/Price Changes', value: 'SIGNIFICANT_CHANGE' },
  { label: 'Newest On Site', value: 'NEWEST' },
  { label: 'Lowest Price', value: 'PRICE_LOW' },
  { label: 'Highest Price', value: 'PRICE_HIGH' },
]

const SEARCH_BY_TEMPLATE_QUERY = gql`
  query savedSearchFromTemplate($link_id: ID!, $data: SearcherInput!) {
    current_user {
      id
      saved_search: templated_search_by_shared_link_id(shareable_search_link_id: $link_id) {
        id
        search_name
        searcher: listing_search(searcher_arguments: $data) {
          ...ListingSearch
        }
      }
    }
  }
  ${listingSearchFragment}
`

const SharedSearchesPage = ({ match }) => {
  const sharedSearchLinkId = match.params.id

  const [selectedSort, setSelectedSort] = useState(sortOptions[0])
  const [bounds, setBounds] = useState({})

  const { data, loading, error } = useQuery(SEARCH_BY_TEMPLATE_QUERY, {
    variables: {
      link_id: sharedSearchLinkId,
      data: {
        sort: selectedSort.value,
        ...bounds,
      },
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    onError: reportError,
  })

  const handleSortChange = sortOption => {
    setSelectedSort(sortOption)
  }

  const handleMapMove = bounds => {
    setBounds(bounds)
  }

  const searchData = data?.current_user?.saved_search || {}
  const noSearchFound = data && !data?.current_user?.saved_search

  if (error || noSearchFound) {
    setTimeout(() => {
      window.location.assign('/homesearch/map')
    }, 10000)

    return (
      <Container>
        <Box py={8} textAlign="center">
          <Typography variant="h1" pb={1}>
            Search can't be found.
          </Typography>
          <Typography pb={4}>
            Please check the URL, reload the page, or go to the map search to create a new search.
          </Typography>

          <Button variant="contained" component={Link} to="/map">
            Go to map search page
          </Button>
        </Box>
      </Container>
    )
  }

  return (
    <Box>
      <Helmet title={loading ? 'Search for Real Estate' : `Searches | ${searchData.search_name}`} />
      <SplitLayoutProvider
        title={searchData.search_name}
        searchData={searchData.searcher || { listings: [] }}
        onMapMove={handleMapMove}
        sortOptions={sortOptions}
        onSortChange={handleSortChange}
        selectedSort={selectedSort}
        loading={loading}
        sharedSearchLinkId={sharedSearchLinkId}
      >
        <SplitLayout />
      </SplitLayoutProvider>
    </Box>
  )
}

export default SharedSearchesPage

import { Component } from 'react'
import { gql } from '@apollo/client'
import { Query } from '@apollo/client/react/components'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { SplitLayoutProvider } from '../Contexts/SplitLayoutContext'
import SplitLayout, { listingSearchFragment } from '../Common/SplitLayout'
import { reportError } from '../../Common/ErrorCapture'
import { HbAnalyticsClass } from '../../Common/AnalyticClasses'

const sortOptions = [
  { label: 'Recent Status/Price Changes', value: 'SIGNIFICANT_CHANGE' },
  { label: 'Newest On Site', value: 'NEWEST' },
  { label: 'Lowest Price', value: 'PRICE_LOW' },
  { label: 'Highest Price', value: 'PRICE_HIGH' },
]

const searcherQuery = gql`
  query AlertSearch($search_id: ID!, $data: SearcherInput!) {
    current_user {
      id
      saved_search: saved_search_by_id(id: $search_id) {
        id
        search_name
        searcher: listing_search(searcher_arguments: $data) {
          ...ListingSearch
        }
      }
    }
  }
  ${listingSearchFragment}
`

class SavedSearch extends Component {
  state = {
    selectedSort: sortOptions[0],
  }

  onSortChange = sortOption => {
    this.setState({ selectedSort: sortOption })
  }

  onMapMove = bounds => {
    this.setState({ bounds })
  }

  render() {
    const savedSearchId = this.props.match.params.id
    const bounds = this.state.bounds || {}
    return (
      <div key={savedSearchId}>
        <Query
          query={searcherQuery}
          variables={{
            search_id: savedSearchId,
            data: {
              sort: this.state.selectedSort.value,
              ...bounds,
            },
          }}
          notifyOnNetworkStatusChange
          fetchPolicy="network-only"
          nextFetchPolicy="cache-first"
          onError={reportError}
        >
          {({ loading, error, data, refetch, networkStatus }) => {
            const searchData =
              data && data.current_user && data.current_user.saved_search
                ? data.current_user.saved_search
                : {}
            const noSearchFound = data && data.current_user && !data.current_user.saved_search
            if (error || noSearchFound) {
              return (
                <div className="text-center mt-2">
                  <h1>Search can't be found.</h1>
                  <p>
                    {'Please check the URL, reload the page, or go to the '}
                    <Link
                      to="/map"
                      className={`${HbAnalyticsClass('errorMapReturn', 'savedSearch')}`}
                    >
                      map search
                    </Link>
                    {' to create a new search.'}
                  </p>
                </div>
              )
            }
            return (
              <div>
                <Helmet title={`Searches - ${searchData.search_name || 'Loading'}`} />
                <SplitLayoutProvider
                  title={searchData.search_name}
                  searchData={searchData.searcher || { listings: [] }}
                  onMapMove={this.onMapMove}
                  sortOptions={sortOptions}
                  onSortChange={this.onSortChange}
                  selectedSort={this.state.selectedSort}
                  loading={loading}
                >
                  <SplitLayout />
                </SplitLayoutProvider>
              </div>
            )
          }}
        </Query>
      </div>
    )
  }
}

export default SavedSearch

import React, { useState, useEffect } from 'react'
import moment from 'moment'
import RsStorage from './../../Utils/RsStorageUtils'
import InfoIcon from '@mui/icons-material/Info'
import { Button, Box, Typography, IconButton, Alert } from '@mui/material'
import { TabletAndDesktop } from '../../Common/ResponsiveComponents'
import CloseIcon from '@mui/icons-material/Close'
const AgentBanner = ({ agent }) => {
  const [showAgentBanner, setShowAgentBanner] = useState(
    agent.agent_mismatch || RsStorage.getOrInitItem('showAgentBanner', true, moment().day(7))
  )

  const handleOnClick = e => {
    e.preventDefault()
    setShowAgentBanner(false)
    RsStorage.setItem('showAgentBanner', false)
  }

  useEffect(() => {
    RsStorage.setItem('showAgentBanner', showAgentBanner)
  }, [showAgentBanner])

  if (!showAgentBanner) {
    return null
  }

  return (
    <TabletAndDesktop>
      <Box
        sx={{
          backgroundColor: 'primary.main',
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'fixed',
          boxShadow: '5px 4px 5px rgba(0, 0, 0, 0.4)',
          bottom: 0,
          left: 0,
          right: 0,
          marginBottom: 0,
          zIndex: 9999999,
          width: '100%',
        }}
        id="agent-buyer-banner"
      >
        {agent.agent_mismatch && (
          <Alert severity="error" sx={{ mb: 2 }}>
            WARNING: You're logged in as a contact who has a different agent than your account.{' '}
            <br />
            You may encounter some unexpected issues. Please sign out & sign in again to reset your
            session.
          </Alert>
        )}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <InfoIcon color="info" size="large" sx={{ mr: 1, color: 'white' }} />
          <Typography variant="h6" color="white">
            Hi {agent.agent_mismatch_name || agent.name}, you're currently viewing the homebuyer
            experience.
          </Typography>
          <Button
            variant="outlined"
            sx={{ ml: 4, borderColor: 'white', color: 'white' }}
            href="/agent"
          >
            Return to Agent Dashboard
          </Button>
          <IconButton onClick={handleOnClick} sx={{ ml: 1, color: 'white' }}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
    </TabletAndDesktop>
  )
}

export default AgentBanner

import { Box, Stack, Typography, Container, Divider } from '@mui/material'
import DisplayStatusBadge from '../DisplayStatusBadge'
import DaysOnSiteBadge from '../DaysOnSiteBadge'

const MobileHeader = ({
  bedsString,
  bathsString,
  sqftString,
  isSold,
  soldDate,
  formattedDisplayPrice,
  displayStatus,
  combinedDisplayStatus,
  showListedAtPrice,
  formattedListedAtPrice,
  address,
  city,
  state,
  postalCode,
  lotSizeValue,
  lotSizeUnits,
  showDaysOnSite,
  daysOnSite,
}) => (
  <>
    <Stack direction="column" spacing={0.5}>
      <Typography variant="h2" color={isSold ? 'text.warning' : 'text.primary'}>
        {formattedDisplayPrice}
      </Typography>
      {showListedAtPrice && (
        <Typography variant="body2">Listed at {formattedListedAtPrice}</Typography>
      )}
      <Stack direction="row" spacing={1} alignItems="center">
        <DisplayStatusBadge
          displayStatus={displayStatus}
          combinedDisplayStatus={combinedDisplayStatus}
          soldDate={soldDate}
        />

        {showDaysOnSite && <DaysOnSiteBadge daysOnSite={daysOnSite} />}
      </Stack>

      <Box>
        <Typography variant="h5" color="text.secondary">
          {address}
        </Typography>
        <Typography variant="h5" color="text.secondary">
          {city}, {state} {postalCode}
        </Typography>
      </Box>
    </Stack>

    <Box pt={1.5}>
      <Stack
        direction="row"
        justifyContent="space-between"
        divider={<Divider orientation="vertical" />}
      >
        <Stack direction="row" spacing={0.5} alignItems="baseline">
          <Typography variant="h5" color="text.primary">
            {bedsString}
          </Typography>
          <Typography variant="h5" color="text.primary" fontWeight="500">
            beds
          </Typography>
        </Stack>

        <Stack direction="row" spacing={0.5} alignItems="baseline">
          <Typography variant="h5" color="text.primary">
            {bathsString}
          </Typography>
          <Typography variant="h5" color="text.primary" fontWeight="500">
            baths
          </Typography>
        </Stack>

        <Stack direction="row" spacing={0.5} alignItems="baseline">
          <Typography variant="h5" color="text.primary">
            {sqftString}
          </Typography>
          <Typography variant="h5" color="text.primary" fontWeight="500">
            sqft
          </Typography>
        </Stack>

        <Stack direction="row" spacing={0.5} alignItems="baseline">
          <Typography variant="h5" color="text.primary">
            {lotSizeValue}
          </Typography>

          <Typography variant="h5" color="text.primary" fontWeight="500">
            {lotSizeUnits} lot
          </Typography>
        </Stack>
      </Stack>
    </Box>
  </>
)

export default MobileHeader

import React, { useState } from 'react'
import {
  Card,
  Divider,
  Stack,
  Button,
  Box,
  TextField,
  Avatar,
  Typography,
  styled,
  useTheme,
} from '@mui/material'
import SendTwoToneIcon from '@mui/icons-material/SendTwoTone'
import RsCard from './UIComponents/Card/RsCard'
import { gql } from '@apollo/client'
import RsCardFooterAction from './UIComponents/Card/RsCardFooterAction'

export const AGENT_FRAGMENT = gql`
  fragment AgentFragment on Agent {
    id
    first_name
    full_name
    photo_url
    company_name
  }
`

export const SEND_QUICK_MESSAGE = gql`
  mutation sendQuickMessage($input: CreateConversationMessageMutationInput!) {
    create_conversation_message(input: $input) {
      conversation {
        id
      }
    }
  }
`

const MessageTextBubble = styled(Card)(
  ({ theme }) => `
        background: ${theme.colors.gradients.black2};
        color: ${theme.colors.alpha.white[100]};
        padding: ${theme.spacing(2)};
        border-radius: ${theme.general.borderRadiusXl};
        border-top-left-radius: ${theme.general.borderRadius};
        display: inline-flex;
        white-space: pre-line;
  `
)

const QuickMessageCard = ({
  agentFirstName,
  agentPhotoUrl,
  agentMessage,
  quickMessages,
  submitMessage,
  textFieldId,
  sendButtonId,
  sendingMessage,
  renderFooterComponent,
  agentCompanyName,
  disableBoxShadow
}) => {
  const [sentMessages, setSentMessages] = useState(quickMessages.map(() => false))
  const [messageText, setMessageText] = useState('')
  const theme = useTheme()

  const handleQuickMessageClick = (index, message, onPressMessage) => {
    onPressMessage ? onPressMessage() : submitMessage(message)
    setSentMessages(sentMessages.map((sent, i) => (i === index ? true : sent)))
  }

  return (
    <>
      <Box display="flex" mb={2} variant="outlined">
        <Box mr={1}>
          <Avatar
            variant="rounded"
            sx={{
              width: 80,
              height: 80,
            }}
            alt={agentFirstName}
            src={agentPhotoUrl}
          />
        </Box>
        <MessageTextBubble sx={{ flexGrow: '1' }}>
          <Typography variant="h6" my="auto" color={theme.colors.alpha.white[100]}>
            {agentMessage}
          </Typography>
        </MessageTextBubble>
      </Box>
      <RsCard
        title={agentFirstName ? `Quick Message to ${agentFirstName}` : 'Quick Message'}
        subheader={agentCompanyName}
        cardProps={{sx: disableBoxShadow ? { boxShadow: 'none'} : {}}}
      >
        <Stack px={2} pt={2} spacing={1} direction={{ xs: 'column', lg: 'row' }}>
          {quickMessages.map(({ message, onPressMessage }, index) => (
            <Button
              key={index}
              disabled={sentMessages[index]}
              variant="outlined"
              color="secondary"
              size="small"
              onClick={() => handleQuickMessageClick(index, message, onPressMessage)}
            >
              {message}
            </Button>
          ))}
        </Stack>
        <Stack px={2} py={2} spacing={1} direction={{ xs: 'column', lg: 'row' }}>
          <Box sx={{ flex: 1 }}>
            <TextField
              id={textFieldId}
              multiline
              fullWidth
              value={messageText}
              label="Write a message"
              placeholder="Write your here message..."
              onChange={e => setMessageText(e.target.value)}
            />
          </Box>
          <Divider orientation="vertical" flexItem xs={{ display: { xs: 'none', md: 'block' } }} />
          <Button
            id={sendButtonId}
            variant="contained"
            disabled={sendingMessage}
            onClick={() => {
              submitMessage(messageText)
              setMessageText('')
            }}
            startIcon={<SendTwoToneIcon />}
          >
            Send
          </Button>
        </Stack>
        {renderFooterComponent && (
          <RsCardFooterAction>{renderFooterComponent()}</RsCardFooterAction>
        )}
      </RsCard>
    </>
  )
}

export default QuickMessageCard

import { gql, useQuery } from '@apollo/client'
import PieChart from 'recharts/lib/chart/PieChart'
import { Pie, Cell, Label } from 'recharts'
import { CardContent, Typography, Box, Link, Button } from '@mui/material'
import MuiErrorBoundarySimple from '@/components/Common/MuiErrorBoundarySimple'
import RsCard from '@/components/Common/UIComponents/Card/RsCard'
import RsCardFooterAction from '@/components/Common/UIComponents/Card/RsCardFooterAction'
import RsSkeletonLoading from '@/components/Common/UIComponents/PageLayout/RsSkeletonLoading'

const CLIMATE_SCORE_QUERY = gql`
  query homebuyerClimateScoreQuery($id: ID!) {
    current_user {
      id
      property_by_id(id: $id) {
        id
        raw_id
        encoded_id
        climate_score {
          target_url
          drought_risk {
            name
            score
            severity
          }
          storm_risk {
            name
            score
            severity
          }
          temp_risk {
            name
            score
            severity
          }
          fire_risk {
            name
            score
            severity
          }
          flood_risk {
            name
            score
            severity
          }
        }
      }
    }
  }
`

const colorFromScore = score => {
  if (score >= 0 && score <= 16) {
    return '#43A047'
  }
  if (score >= 17 && score <= 33) {
    return '#7CB342'
  }
  if (score >= 34 && score <= 50) {
    return '#FDD835'
  }
  if (score >= 51 && score <= 67) {
    return '#FFA000'
  }
  if (score >= 68 && score <= 84) {
    return '#E65100'
  }
  if (score >= 85) {
    return '#B71C1C'
  }
}

const Charts = ({ climateScore }) => {
  const filteredKeys = Object.keys(climateScore).filter(
    k => !(k === '__typename' || k === 'target_url')
  )
  return filteredKeys.map((key, idx) => {
    const { name } = climateScore[key]
    const { score } = climateScore[key]
    const { severity } = climateScore[key]
    const color = colorFromScore(score)
    const colors = [color, `${color}80`]
    const data = [
      { name, value: score },
      { name: '', value: 100 - score },
    ]

    return (
      <Box key={name} width="33%" display="flex" justifyContent="center">
        <Box>
          <PieChart width={80} height={80}>
            <Pie
              data={data}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              innerRadius={15}
              outerRadius={30}
              paddingAngle={0}
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={colors[index]} />
              ))}
              <Label
                value={score}
                position="center"
                fill="#333333"
                style={{
                  fontSize: '16px',
                  fontWeight: 'bold',
                }}
              />
            </Pie>
          </PieChart>
          <Box sx={{ minWidth: 90 }}>
            <Typography fontWeight="bold">{name}</Typography>
            <Typography>{severity}</Typography>
          </Box>
        </Box>
      </Box>
    )
  })
}

const HeaderContent = () => (
  <Box textAlign="center">
    <Typography variant="body2" pb={1}>
      A 0-100 rating based on this home's future risk of climate-related hazards compared to other
      homes in the United States.
    </Typography>
    <Link href="https://climatecheck.com/our-methodologies" target="_blank" rel="noreferrer">
      Learn more at ClimateCheck.com
    </Link>
  </Box>
)

const UnableToLoad = () => (
  <RsCard title="Climate Risk Ratings" cardProps={{ sx: { boxShadow: 'none' } }}>
    <CardContent>
      <HeaderContent />
      <Typography pt={2} textAlign="center">
        Sorry, we're unable to calculate ClimateCheck™ ratings for this listing.
      </Typography>
    </CardContent>
  </RsCard>
)

const Loading = () => (
  <RsCard title="Climate Risk Ratings" cardProps={{ sx: { boxShadow: 'none' } }}>
    <CardContent>
      <RsSkeletonLoading height="200px" />
    </CardContent>
  </RsCard>
)

const ClimateScore = ({ propertyId }) => {
  const { data, loading } = useQuery(CLIMATE_SCORE_QUERY, {
    variables: {
      id: propertyId,
    },
  })

  if (loading) {
    return <Loading />
  }

  if (!data) {
    return <UnableToLoad />
  }

  const climateScoreData = data?.current_user?.property_by_id?.climate_score || {}

  if (!climateScoreData.fire_risk) {
    return <UnableToLoad />
  }

  return (
    <RsCard title="Climate Risk Ratings" cardProps={{ sx: { boxShadow: 'none' } }}>
      <MuiErrorBoundarySimple>
        <CardContent>
          <HeaderContent />
          <Box display="flex" justifyContent="center" flexWrap="wrap" pt={2} width="100%" mx="auto">
            <Charts climateScore={climateScoreData} />
          </Box>
        </CardContent>
        <RsCardFooterAction>
          <Box textAlign="center" width="100%">
            <Button
              href={climateScoreData.target_url}
              target="_blank"
              aria-disabled="true"
              rel="noreferrer"
              variant="contained"
            >
              Get a Full Report
            </Button>
          </Box>
        </RsCardFooterAction>
      </MuiErrorBoundarySimple>
    </RsCard>
  )
}

export default ClimateScore

import { useState, useContext } from 'react'
import {
  Button,
  CardContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  Box,
  Divider,
  Card,
} from '@mui/material'
import { gql, useQuery } from '@apollo/client'
import { CurrentUserContext } from '../../Contexts/CurrentUserContext'
import RsModal from '../../../Common/UIComponents/Modal/RsModal'
import RsCardFooterAction from '../../../Common/UIComponents/Card/RsCardFooterAction'
import CreateSavedSearchButton from './CreateSavedSearchButton'
import { CLIENT_CREATE_SEARCH_SCHEDULE_OPTIONS } from '@/components/Common/Constants/ListingAlertConstants'

const SAVED_SEARCH_TEMPLATE_SUMMARY_QUERY = gql`
  query savedSearchTemplateSummaryQuery($id: ID!) {
    current_user {
      id
      templated_search_by_shared_link_id(shareable_search_link_id: $id) {
        id
        search_name
        schedule
        summary(keyword_display_limit: 5) {
          field
          value
        }
      }
    }
  }
`

const CreatedSavedSearchFromShareableLinkModal = ({ isMobile, sharedSearchLinkId }) => {
  const { loggedIn, showSignUp } = useContext(CurrentUserContext)

  const [searchName, setSearchName] = useState('')
  const [selectedSchedule, setSelectedSchedule] = useState(
    CLIENT_CREATE_SEARCH_SCHEDULE_OPTIONS[0].value
  )

  const { data, loading } = useQuery(SAVED_SEARCH_TEMPLATE_SUMMARY_QUERY, {
    variables: {
      id: sharedSearchLinkId,
    },
    onCompleted: data => {
      const templateName = data?.current_user?.templated_search_by_shared_link_id?.search_name
      if (templateName) {
        setSearchName(templateName)
      }

      const templateSchedule = data?.current_user?.templated_search_by_shared_link_id?.schedule

      if (
        templateSchedule &&
        CLIENT_CREATE_SEARCH_SCHEDULE_OPTIONS.map(item => item.value).includes(templateSchedule)
      ) {
        setSelectedSchedule(templateSchedule)
      }
    },
    skip: !loggedIn,
  })

  const handleScheduleChange = event => {
    setSelectedSchedule(event.target.value)
  }

  const handleOpen = onOpen => {
    loggedIn ? onOpen() : showSignUp()
  }

  const templateSummary = data?.current_user?.templated_search_by_shared_link_id?.summary || []

  return (
    <RsModal
      title="Save Search"
      id="create-saved-search-from-template"
      modalStylesProp={{ width: '500px' }}
      renderTriggerComponent={({ onOpen }) => (
        <Button
          fullWidth={isMobile}
          sx={{
            '&:focus': { outline: 'none' },
          }}
          variant={isMobile ? 'contained' : 'outlined'}
          onClick={() => handleOpen(onOpen)}
        >
          Save Search
        </Button>
      )}
      renderContentComponent={({ onClose }) => (
        <>
          <CardContent>
            <Stack spacing={2} mt={1}>
              <TextField
                fullWidth
                label="Search Name"
                variant="outlined"
                name="search_name"
                id="search_name"
                value={searchName}
                onChange={e => setSearchName(e.target.value)}
              />
              <FormControl fullWidth>
                <InputLabel>Receive new alerts</InputLabel>
                <Select
                  value={selectedSchedule}
                  label="Receive new alerts"
                  name="alert_schedule"
                  id="alert_schedule"
                  onChange={handleScheduleChange}
                >
                  {CLIENT_CREATE_SEARCH_SCHEDULE_OPTIONS.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <Box>
                <Typography fontWeight="bold" pb={1} color="text.secondary">
                  Search Summary
                </Typography>

                {templateSummary.length ? (
                  <Card variant="outlined" sx={{ boxShadow: 'none' }}>
                    <Stack
                      direction="column"
                      divider={<Divider />}
                      sx={{ maxHeight: '200px', overflowY: 'scroll' }}
                    >
                      {templateSummary.map(summarySet => (
                        <Stack
                          direction="row"
                          spacing={4}
                          key={summarySet.field}
                          justifyContent="space-between"
                          p={2}
                        >
                          <Typography fontWeight="500">{summarySet.field}</Typography>
                          <Typography textAlign="right">{summarySet.value}</Typography>
                        </Stack>
                      ))}
                    </Stack>
                  </Card>
                ) : (
                  <Typography>No summary available.</Typography>
                )}
              </Box>
            </Stack>
          </CardContent>
          <RsCardFooterAction>
            <CreateSavedSearchButton
              sharedSearchLinkId={sharedSearchLinkId}
              name={searchName}
              schedule={selectedSchedule}
              onCompleted={onClose}
              shouldDisable={loading}
            />
          </RsCardFooterAction>
        </>
      )}
    />
  )
}

export default CreatedSavedSearchFromShareableLinkModal
